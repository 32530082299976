import useAuth from 'app/hooks/useAuth';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';

function ConfirmationDialogRaw(props) {
  const { onClose, value: valueProp, open, ...other } = props;
  //const [value, setValue] = useState(valueProp);
  const radioGroupRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    /*  if (!open) {
      setValue(valueProp);
    }*/
  }, [open]);

  function handleEntering() {
    if (radioGroupRef.current !== null) {
      radioGroupRef.current.focus();
    }
  }

  const handleOk = () => navigate('/Account');

  //const handleChange = (_, newValue) => setValue(newValue);

  return (
    <Dialog
      maxWidth="xs"
      disableEscapeKeyDown
      TransitionProps={{ onExiting: handleEntering }}
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
    >
      <DialogTitle id="confirmation-dialog-title">Update account</DialogTitle>
      <DialogContent>
        <DialogContentText>Complete the setup of your reseller account .</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOk} color="primary">
          ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmationDialogRaw.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
  // value: PropTypes.string.isRequired
};
const AuthGuard = ({ children }) => {
  const { isAuthenticated, user } = useAuth();
  const { pathname } = useLocation();
  const [open, setOpen] = useState(true);

  function handleClose(newValue) {
    setOpen(false);
  }

  useEffect(() => {
    if (user) {
      if (pathname !== '/Account' && user.bankName === '') {
        setOpen(true);
      } else {
        setOpen(false);
      }
    }
  }, [open, pathname, user]);
  if (isAuthenticated)
    return (
      <>
        {pathname !== '/Account' && user && user.bankName === '' ? (
          <>
            {children}
            <ConfirmationDialogRaw
              keepMounted
              open={open}
              className="paper"
              id="ringtone-menu"
              onClose={handleClose}
            />
          </>
        ) : (
          children
        )}
      </>
    );

  return <Navigate replace to="/session/signin" state={{ from: pathname }} />;
};

export default AuthGuard;
